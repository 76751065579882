<template>
    <div class="policy">
        <!--三项政策-->
        <div class="sxzc w-1400">
            <div class="policy-h3 clearfix">
                <div class="fl">三农政策</div>
                <div class="fr">
                    <router-link to="/List?infoType=249253465337925&type=三农政策"> 更多<i class="el-icon-arrow-right"></i></router-link>
                </div>
            </div>
            <div class="sxzc-mian mt-10">
                <el-row :gutter="30">
                    <!-- <el-col :span="5">
                        <div class="sxzc-tab">
                            <div class="sxzc-tab-item" v-for="(item) in tabList" :key="item" :class="{act:item== SXZCparams.filter.secondInfoType}" @click="change(item)">
                                <i class="el-icon-postcard"></i> {{item}}
                            </div>
                        </div>
                    </el-col> -->
                    <el-col :span="13">
                        <div class="sxzc-cal">
                            <el-carousel trigger="click" height="510px"  arrow="never">
                                <el-carousel-item v-for="item in SXZCdata.slice(0,4)" :key="item.id+'cal'">
                                    <router-link :to="`/detail?id=${item.id}&type=三农政策&oParams=${JSON.stringify(SXZCparams)}`">
                                        <el-image style="width:100%;height:100%" :src="item.photoUrls"></el-image>
                                    </router-link>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </el-col>
                    <el-col :span="11">
                        <div class="sxzc-list">
                            <div class="zc-item ellipsis" v-for="item in SXZCdata" :key="item.id" >
                                <router-link :to="`/detail?id=${item.id}&type=三农政策&oParams=${JSON.stringify(SXZCparams)}`">{{item.title}}</router-link>                                
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--补贴政策-->
        <div class="butie w-1400">
            <div class="policy-h3 clearfix">
                <div class="fl">补贴政策</div>
                <div class="fr">
                    <router-link to="/List?infoType=249253553061957&type=补贴政策">
                        更多<i class="el-icon-arrow-right"></i>
                    </router-link>
                </div>
            </div>
            <!-- <div class="butie-submenu">
                <el-row :gutter="100">
                    <el-col :span="6" v-for="(item,k) in tabList" :key="k + 'menu'">
                        <div class="menu-item" :class="{act:item === BTZCparams.filter.secondInfoType}" @click="change1(item)">
                            <i class="el-icon-tickets"></i>  {{item}}
                        </div>
                    </el-col>
                </el-row>
            </div> -->
            <div class="gg-main mt-40">
                <el-row :gutter="30">
                    <el-col :span="14">
                        <div class="gg-banner">
                            <div class="gg-pic-big">
                                <router-link :to="`/detail?id=${BTZCdata[(Math.abs(transNum/237)+1)].id}&type=补贴政策&oParams=${JSON.stringify(BTZCparams)}`">
                                    <el-image :src="BTZCdata[(Math.abs(transNum/237)+1)].photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                </router-link>
                            </div>
                            <div class="cal-small">
                                <ul class="cal-small-list clearfix" :class="{istrans}" :style="`width:${237*calList.length}px;transform:translateX(${transNum}px)`">
                                    <li class="cal-smal-item fl" :class="{isSlace:(Math.abs(transNum/237)+1 === index)}" v-for="(item,index) in calList" :key="index +'cal'">
                                        <div class="star-pic">
                                            <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                        </div>
                                        <div class="ceng" v-show="!(Math.abs(transNum/237)+1 === index)"></div>
                                    </li>
                                </ul>
                                <div class="cal-btn prev" @click="prev"><i class="el-icon-arrow-left"></i></div>
                                <div class="cal-btn next" @click="next"><i class="el-icon-arrow-right"></i></div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="gg-list">
                            <ul>
                                <li class="gg-item" v-for="item in BTZCdata" :key="item.id" @click="goDetail(item.id,'补贴政策',JSON.stringify(BTZCparams))" >
                                    <div class="gg-text ellipsis">{{item.title}}</div>
                                    <div class="gg-time">{{item.releaseDate&&item.releaseDate.substr(0,10)}}</div>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--农村新貌-->
        <div class="nongcun w-1400">
            <div class="policy-h3 clearfix">
                <div class="fl">补贴申请</div>
                <div class="fr">
                    <router-link to="/List?infoType=455192826572869&type=补贴申请">
                        更多<i class="el-icon-arrow-right"></i>
                    </router-link>
                </div>
            </div>
            <div class="nc-main mt-10">
                <div class="nc-box">
                    <router-link :to="`/detail?id=${ncCalList[(Math.abs(transNumNc/245)+2)].id}&type=补贴申请&oParams=${JSON.stringify(NCXMparams)}`">
                        <el-image :src="ncCalList[(Math.abs(transNumNc/245)+2)].photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                    </router-link>
                </div>
                <div class="nc-name">{{ncCalList[(Math.abs(transNumNc/245)+2)].title}}</div>
                <div class="car-box">
                    <div class="nc-cal-small">
                        <ul class="cal-small-list clearfix" :class="{istransNc}" :style="`width:${245*ncCalList.length*2}px;transform:translateX(${transNumNc}px)`">
                            <li class="nc-cal-smal-item fl" :class="{isSlaceNc:(Math.abs(transNumNc/245)+2 === index)}" v-for="(item,index) in ncCalList" :key="index +'cal'">
                                <div class="nc-star-pic">
                                    <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                </div>
                                <div class="ceng" v-show="!(Math.abs(transNumNc/245)+2 === index)"></div>
                            </li>
                        </ul>
                        <div class="nc-cal-btn prev" @click="ncPrev"><i class="el-icon-arrow-left"></i></div>
                        <div class="nc-cal-btn next" @click="ncNext"><i class="el-icon-arrow-right"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {GetList} from '@/api/other.js'
export default {
    data(){
        return{
            transNum:0,
            transNumNc:0,
            ncCalList:[{},{},{},{},{}],
            calList:[{},{},{},{},{}],
            istrans:true,
            istransNc:true,
            actimg:'',
            tabList:['城镇职工社会保险','考试招生','住房保障与房地产','医疗保险','市场监管','身份户籍','交通出行','卫生健康'],
            SXZCparams:{//三农政策
                currentPage: 1,
                filter: {infoType:249253465337925,},
                pageSize: 12,
            },
            SXZCdata:[],
            BTZCparams:{//补贴政策
                currentPage: 1,
                filter: {infoType:249253553061957},
                pageSize: 16,
            },
            BTZCdata:[{},{}],
            NCXMparams:{//补贴申请
                currentPage: 1,
                filter: {infoType:455192826572869,},
                pageSize: 8,
            },
        }
    },
    methods:{
        ncNext(){
            if(this.transNumNc === -this.ncCalList.length*245/2){
                this.transNumNc = 0
                this.istransNc = false;
                setTimeout(() => {
                    this.istransNc = true;
                    this.transNumNc -= 245
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNumNc -= 245
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        ncPrev(){
            if(this.transNumNc === 0){
                this.transNumNc = -this.ncCalList.length*245/2
                this.istransNc = false;
                setTimeout(() => {
                    this.istransNc = true;
                    this.transNumNc += 245
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNumNc += 245
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        getNCXMdata(){
            GetList(this.NCXMparams).then(res=>{
                this.ncCalList = res.data.data.list;
                this.ncCalList =this.ncCalList.concat(this.ncCalList)
            })
        },
        next(){
            if(this.transNum === -this.calList.length*237/2){
                this.transNum = 0
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum -= 237
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum -= 237
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        prev(){
            if(this.transNum === 0){
                this.transNum = -this.calList.length*237/2
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum += 237
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum += 237
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        change(a){
            this.SXZCparams.filter.secondInfoType = a;
            this.getSXZCdata();
        },
        getSXZCdata(){
            GetList(this.SXZCparams).then(res=>{
                this.SXZCdata = res.data.data.list;
            })
        },
        getBTZCdata(){
            GetList(this.BTZCparams).then(res=>{
                this.BTZCdata = res.data.data.list;
                this.calList = res.data.data.list.slice(0,5);
                this.calList =this.calList.concat(this.calList)
            })
        },
        change1(a){
            this.BTZCparams.filter.secondInfoType = a;
            this.getBTZCdata();
        },
        goDetail(id,type,oParams){
            this.$router.push({path:'/detail',query:{id,type,oParams}})
        },
    },
    mounted(){
        this.getSXZCdata();
        this.getBTZCdata();
        this.getNCXMdata();
    }
}
</script>
<style scoped>
.isSlaceNc{
    transform: scale(1.2);
    border: 2px solid #fff;
}
.isSlaceNc .star-info{
    background: #f37b1c;
    color: #fff;
}
.nc-cal-btn{
    width: 50px;
    height: 80px;
    line-height: 80px;
    font-size: 50px;
    font: 700;
    position: absolute;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, .4);
    z-index: 20;
}
.nc-cal-btn:hover{
    background: rgba(255, 255, 255, 1);
}
.nc-star-pic{
    height: 142.5px;
}
.nc-cal-smal-item{
    width: 220px;
    height: 142.5px;
    overflow: hidden;
    margin-right: 25px;
    position: relative;
    transition: transform 1.2s;
    box-sizing: border-box;
}
.nc-cal-small{
    height: 160px;
    padding-top:8px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.car-box{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 175px;
    padding-top: 8px;
    width: 100%;
}
.nc-name{
    padding: 10px 20px;
    font-size: 20px;
    background: #3aceda;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
}
.nc-box{
    height: 100%;
}
.nc-main{
    height: 700px;
    overflow: hidden;
    position: relative;
}
.nongcun .t2{
    display: flex;
    justify-content: space-between;
}
.nongcun .t2>span:last-child{
    font-size: 16px;
    font-weight: 400;
}
.nongcun .t2>span:last-child:hover{
    color: #666;
    cursor: pointer;
}
.gg-text.ellipsis{
    width: 75%;
}
.mt-40{
    margin-top: 40px;
}
.gg-item:hover{
    color: #0e6ac1;
    cursor: pointer;
}
.gg-item{
    line-height: 36.25px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
}
.gg-list{
    padding: 20px;
    border: 1px solid #E4E7ED;
    border-radius: 3px;
    height: 620px;
    overflow: hidden;
}
.gg-banner{
    position: relative;
    height: 620px;
    overflow: hidden;
}
.gg-pic-big{
    height: 500px;
}
.istrans{
    transition: transform 1.2s;
}
.istransNc{
    transition: transform 1.2s;
}
.prev{
    left: 0px;
}
.next{
    right: 0;
}
.star-pic{
    height: 120px;
}
.cal-btn{
    width: 30px;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, .4);
    z-index: 20;
}
.cal-btn:hover{
    background: rgba(255, 255, 255, 1);
}
.ceng{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
}
.cal-smal-item{
    width: 212px;
    height: 120px;
    overflow: hidden;
    margin-right: 25px;
    position: relative;
    transition: transform 1.2s;
    box-sizing: border-box;
}
.cal-small{
    height: 140px;
    /* margin-top: 20px; */
    padding-top: 15px;
    width: 686px;
    margin: 20px auto;
    overflow: hidden;
    position: relative;
    bottom: 20px;
}
.menu-item{
    margin-top: 30px;
}
.butie-submenu{
    padding: 0px 40px;
}
.zc-item:hover{
    color:#0e6ac1;
    cursor: pointer;
}
.zc-item{
    line-height: 40px;
    font-size: 16px;
    border-bottom:1px dashed #eee;
}
.sxzc-list{
    padding: 15px 20px;
    height: 510px;
    overflow: hidden;
    border: 1px solid #E4E7ED;
    border-radius: 3px;
}
.sxzc-cal >>> .el-carousel__button{
    height: 6px;
    width: 50px;
}
.sxzc-cal{
    height: 510px;
}
.sxzc-tab-item~.sxzc-tab-item{
    margin-top: 10px;
}
.sxzc-tab-item:hover,.sxzc-tab-item.act,.menu-item:hover,.menu-item.act{
    color:#fff;
    background: #0e6ac1;
    cursor: pointer;
}
.sxzc-tab-item,.menu-item{
    height: 55px;
    line-height: 55px;
    background: #ddd;
    border-radius: 6px;
    font-size: 20px;
    padding-left: 8px;
    font-weight: 800;
    font-family: '宋体';
}
.sxzc-tab{
    height: 510px;
}
.policy-h3{
    line-height: 42px;
    font-size: 20px;
    border-bottom: 1px solid #eee;
}
.policy a{
    color: #666;
}
.policy{
    font-family: '微软雅黑';
    color: #666;
}
</style>